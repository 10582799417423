

























import {Component, Prop, Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import {FOCUS_LEAGUE_ID, FOCUS_SEASON_ID} from '@/store/context.store';
import SEASON_SERVICE from '@/services/season.service';
import {CustomComponent} from '@/model/site/components.model';

@Component({
  components: {},
})
export default class StatsBaseLayout extends Vue {

  @Prop({default: () => []}) selectionComponents!: CustomComponent[];

  @Getter(FOCUS_SEASON_ID) focusSeasonId!: string;
  @Getter(FOCUS_LEAGUE_ID) focusLeagueId!: string;

  get seasonDesc() {
    if (!this.focusSeasonId) {
      return '';
    }
    return SEASON_SERVICE.seasonDesc(this.focusSeasonId, true, 'Saison ');
  }
}
