









import {Component, Vue} from 'vue-property-decorator';
import {FORUM_CONFIG} from '@/store/context.store';
import {Getter} from 'vuex-class';
import {ForumConfig} from '@/model/site/context.model';
import HfAdBox from '@/components/ads/HfAdBox.vue';
import {HfPage} from '@/model/site/components.model';
import MetaMixin from '@/mixins/meta.mixin';
import SportLayout from '@/components/layout/SportLayout.vue';
import VueFriendlyIframe from 'vue-friendly-iframe';
import MobileMixin from '@/mixins/mobile.mixin';
import ContainerLayout from '@/components/layout/ContainerLayout.vue';
import StatsBaseLayout from '@/components/layout/StatsBaseLayout.vue';

Vue.use(VueFriendlyIframe);

@Component({
  mixins: [MetaMixin, MobileMixin],
  metaInfo() {
    return this.metaInfo;
  },
  components: {StatsBaseLayout, ContainerLayout, SportLayout, HfAdBox},
})
export default class ForumPage extends Vue implements HfPage {

  @Getter(FORUM_CONFIG) forumConfig!: ForumConfig;

  get forumUrl() {
    const baseUrl = this.forumConfig.includeUrl;
    const props = Object.getOwnPropertyNames(this.$route.query).map(key => `${key}=${this.$route.query[key]}`);
    const params = props.join('&');
    const forumUrl = `${baseUrl}?redirected=true&${params}`;
    Vue.$log.info('Forum URL', forumUrl);
    return forumUrl;
  }

  get headTitle() {
    return 'Forum';
  }

  get headDescription() {
    return '';
  }

  get canonicalUrl() {
    return this.forumUrl;
  }
}
