



























import {Component, Vue} from 'vue-property-decorator';
import HfAdBox from '@/components/ads/HfAdBox.vue';

@Component({
  components: {HfAdBox},
})
export default class ContainerLayout extends Vue {

}
